import TooltipGenerator from "./gh_element/TooltipGenerator.js";
angular.module('ghTooltipModule', [])
.directive('ghToolTip', ['$compile', 'fileManager', 'cnfg', 'PipeService', '$window', 'itemsDataProcessing',($compile, fileManager, cnfg, PipeService, $window, itemsDataProcessing) => {
  return {
    restrict: 'E',
    scope: {
      model: '=',
      mode: '@?'
    },
    template:
      '<span class="field-tooltip">'+
      '<span ng-mouseenter="mouseEnterTarget($event)" ng-if="!mode" class="field-tooltip-icon">?</span>'+
      '<div ng-style="position" ng-class="{no_positioning: mode}" class="field-tooltip-wrap"><div class="field-tooltip-container"></div></div>'+
      '</span>',
    link: async function(scope, element) {
      scope.position = {
        'top': '0',
        'left': '0'
      };

      const helpApp = await gudhub.getApp(cnfg.app_help.app_id);
      scope.isHasEditPermission = !!helpApp && helpApp.permission > 1;
      
      async function getTooltip() {
        if (
            scope.model &&
            scope.model.data_model.tooltip &&
            scope.model.data_model.tooltip.filter_valuesArray
        ) {

          const filterList = [
            {
              data_type: "text",
              field_id: cnfg.app_help.field_id_map,
              search_type: "equal_and",
              valuesArray: scope.model.data_model.tooltip.filter_valuesArray,
            },
          ];
          
          const items = await gudhub.getItems(cnfg.app_help.app_id);
          const itemsList = await gudhub.util.getFilteredItems(items, filterList);

          if (itemsList.length) {
            let modelAddress = {
              app_id: cnfg.app_help.app_id,
              item_id: itemsList[0].item_id,
              field_id: cnfg.app_help.field_id_link,
            };
            const linkToMd = await gudhub.getFieldValue(modelAddress.app_id, modelAddress.item_id, modelAddress.field_id);

            if(linkToMd) {
            
              const elementTags = scope.model.data_model.tooltip.filter_valuesArray.join(',').replace(',', '_');
              const Tooltip = new TooltipGenerator(items, linkToMd, elementTags);
              
              await Tooltip.setMarkdown();
              
              Tooltip.replaceLinks(itemsList[0]);

              if(Tooltip.isCommentsExist(scope.model.name_space)) {
                Tooltip.removeComments();
                scope.md = Tooltip.getMarkdown();
              }

              if(scope.mode) {
                
                render('<gh-loader color="blue"></gh-loader>');
                
                Tooltip.replaceImages();
                const md = Tooltip.getMarkdown();
                
                // Get instance of markdown and pass MD to it with setter in web component
                gudhub.ghconstructor.getInstance('markdown_viewer').then(() => {
                  const viewer = document.createElement('markdown-viewer');
                  viewer.setAttribute('mode', 'html');
                  viewer.data.md = md;
                  render(viewer);
                })
              }
              
            }

          }
        }
      }
      await getTooltip();

      
      function render(tooltip) {
        const template_string = `<div class="field-tooltip-block">
                                    <div>${!(tooltip instanceof HTMLElement) ? tooltip : ''}</div>
                                 </div>`;
        const linkFn = $compile(template_string);

        angular.element( element[0].querySelector(".field-tooltip-container") ).empty().append(linkFn(scope));
        if(tooltip instanceof HTMLElement) {
          element[0].querySelector('.field-tooltip-block').append(tooltip);
        }
      }

      function removeTooltip () {
        angular.element( element.remove());
      }
      
      scope.mouseEnterTarget = function (event) {
        scope.position.top = event.clientY + "px";
        scope.position.left = event.clientX + "px";

        // We need check if help there is for this field - if no it will render default tooltip in model
        if (scope.md) {
          render('<gh-loader color="blue"></gh-loader>');

          gudhub.ghconstructor.getInstance('markdown_viewer').then(() => {
            const viewer = document.createElement('markdown-viewer');
            viewer.setAttribute('mode', 'html');
            viewer.data.md = scope.md;
            render(viewer);
          })

        } else if(scope.model.data_model.tooltip.default){
          render(scope.model.data_model.tooltip.default);
        } else {
          removeTooltip();
        }

      }

    }
  };
}]);