
require('./gh_element_editor.js');
require('../app_processor.js');
// import "./../../automation/gh_automation";
import Rete from "rete";
import { AutomationInstanceCreator } from './../../automation/AutomationInstanceCreator.js';

/************************************************************************************************************|
|*********************************************    GH-ELEMENT    *********************************************|
|************************************************************************************************************|
|  ┌──────────────────────────────────────────────────────────────────────────────────────────────────────┐
|  │                               <div gh-element="open_item_action" ></div>                             │
|  └──────────────────────────────────────────────────────────────────────────────────────────────────────┘
|
|  gh-element could be:
|      ◆ action - used to make action with data
|      ◆ field  - used to render fields
|      ◆ elem-src  - used to set source fields for interpretation
|
|**********************************************************************************************************|
*/


angular.module('ghElement', [
    'ghElementEditorModule',
    'mainStorage',
    'ghConstructor',
    'appDataProcessor',
    'mainStorage',
    'pipeModule',
    'config',
    'authorizationMod',
    'ghAutomationModule'
])

.directive('ghElementWidget', ['appDataProcesingService', 'GHConstructor', 'GhElementFactory', 'storage', function(appDataProcesingService, GHConstructor, GhElementFactory, storage) {
    var directive = {
        restrict: 'E',
        scope: {
            appId: '=',
            fieldId: '=',
            authKey: '='
        },

        controller: ['$scope', function($scope) {
            storage.updateUser({
                auth_key: $scope.authKey,
                expirydate: 0
            });
        }],

        link: function(scope, elem, attrs) {
            appDataProcesingService.getApp(scope.appId).then(function(app) {
                scope.window = {
                    data: {
                        fields: app ? app.field_list: {}
                    }
                };

                angular.forEach(app.field_list, function(field) {
                    if (scope.fieldId == field.field_id) {
                        GHConstructor.getInstance(field.data_type).then(function(data) {
                            attrs.action = scope.fieldId;
                            switch (data.getTemplate().constructor) {
                                case 'action':
                                    GhElementFactory.action(scope, elem, attrs);
                                    break;
                                case 'field':
                                    GhElementFactory.field(scope, elem, attrs);
                                    break;
                            }
                        });

                    }
                });
            });
        }
    };

    return directive;
}])

.directive('ghElementContainer', ['storage', 'authApi', '$window', '$injector', function(storage, authApi, $window, $injector) {
    var directive = {
        restrict: 'E',

        controller: ['$scope', function($scope) {
            // Temporary fix for ghconstructor in library.
            // Need to be deleted after new ghconstructor in library will be finished
            gudhub.ghconstructor.initAngularInjector($injector);
            $scope.isTokenReady = false;
            if ($window.localStorage.auth_key) {
                authApi.updateToken($window.localStorage.auth_key).then(function(res) {
                    storage.updateUser(res);
                    $scope.isTokenReady = true;
                    $scope.$apply();
                });
            } else {
                var watcher = $scope.$watch(function() {
                    return $window.localStorage.auth_key;
                }, function(n) {
                    if (n) {
                        authApi.updateToken($window.localStorage.auth_key).then(function(res) {
                            storage.updateUser(res);
                            $scope.isTokenReady = true;
                            $scope.$apply();
                            watcher();
                        });
                    }
                });
            }

        }]
    };

    return directive;
}])


/*============================================================================================================|
|===========================================  GH-ELEMENT DIRECTIVE   =========================================|
|=============================================================================================================|
| GH-ELEMENT do the next things:
|  - creates field_model that is used for rendering of field or action
|  - listen for updates in pipe of field_value from out side and then send it to field
|  - sends updates of field_value to pipe
|  - listen for updates of field_model from out side and rerender field or action
|
| GH-ELEMENT has following data:
|  - appId: '@?'
|  - itemId: '@?'
|  - fieldId: '@?'
|  - elemSrc: '@?'
|  - decorator: '=?'
|  - value: '=?'
|  - field_model:
*/
.directive('ghElement', ['GHConstructor', 'GhElementFactory', 'PipeService', '$timeout', '$compile', 'UtilsService', '$injector', 'cnfg', function(GHConstructor, GhElementFactory, PipeService, $timeout, $compile, UtilsService, $injector, cnfg) {

    var directive = {
        restrict: 'AE',
        scope: {
            appId: '@?',
            itemId: '@?',
            fieldId: '@?',
            containerId: '@?',
            elemSrc: '@?',
            decorator: '=?',
            value: '=?'
        },
        controller: ['$scope', '$element', '$attrs', 'PipeService', 'GHConstructor', 'Message', 'GhElementEditor', '$location', 'formEditorService', '$routeParams', '$injector', function($scope, $element, $attrs, PipeService, GHConstructor, Message, GhElementEditor, $location, formEditorService, $routeParams, $injector) {
            $scope.update_state = false; // value 
            $scope.field_model = {
                settings: {},
                container_id: $scope.containerId
            };

            var value_address = {};
            var oldValue = null;
            var newValue = null;

            Object.defineProperty($scope.field_model, 'field_value', {
                get: function() {
                    return $scope.value;
                },
                set: function(newVal) {
                    if (newVal != $scope.value) {
                        oldValue = $scope.value;
                        newValue = newVal;
                        $scope.value = newVal;
                        

                        if (value_address.item_id) {
                            value_address.new_value = newVal;
                            PipeService.emit('gh_value_set', {}, value_address);
                        }

                    }
                }
            });

            $scope.history = [];

            if ($scope.appId && $scope.fieldId) {
                var model_address = {
                    app_id: $scope.appId,
                    field_id: $scope.fieldId
                };

                let modelPipe = function modelPipe(event, field_model = {}) {
                    var tempInterpr;
                    if ($scope.decorator && $scope.decorator.data_model && $scope.decorator.data_model.interpretation) {
                        tempInterpr = angular.copy(field_model.data_model.interpretation);
                        UtilsService.mergeInterpretations(tempInterpr, $scope.decorator.data_model.interpretation, 'src');
                    }
                    angular.merge($scope.field_model, angular.merge(angular.copy(field_model) , $scope.decorator));
                    if ($scope.decorator && $scope.decorator.data_model && $scope.decorator.data_model.interpretation) {
                        $scope.field_model.data_model.interpretation = tempInterpr;
                    }

                    if (field_model.data_model && field_model.data_model.code) {
                        eval(field_model.data_model.code);
                    }

                    //-- automation init
                    if ($scope.itemId && $scope.field_model.data_model &&  $scope.field_model.data_model.hasOwnProperty('automation')){
                        initAutomation();
                    }
                    
                    rendering().then(() => $scope.$digest())
            
                    // Destroy init field_model event
                    PipeService.destroy('gh_model_get gh_model_update', model_address, modelPipe);
               
                };


                PipeService 
                    .on('gh_model_get gh_model_update', model_address, modelPipe, $scope)
                    .emit('gh_model_get', {}, model_address);

                
                value_address = {
                    app_id: $scope.appId,
                    item_id: $scope.itemId,
                    field_id: $scope.fieldId
                };

                async function decorateElementSettingsCallback(e, field_model) {

                    angular.merge($scope.field_model, angular.merge(angular.copy(field_model), $scope.decorator));
                    
                    rendering().then(() => $scope.$digest());
                }

                gudhub.on('settings_decorator', value_address, decorateElementSettingsCallback);

                // for calculator (example app team vacation)
                $scope.callbacks = [];

                function pipeFn(event, field_value) {
                    $scope.value = field_value;
                    if ($scope.callbacks.length) {
                        $scope.callbacks.forEach((cb) => cb(value_address.item_id));
                    }
                    $scope.$digest();
                }
                
                function pipeFnGet(event, field_value) {
                    $scope.value = field_value;
                    if ($scope.callbacks.length ) {
                        if($scope.field_model.field_value == undefined){
                            $scope.callbacks.forEach((cb) => cb(value_address.item_id))
                        } else {
                            $scope.callbacks.forEach((cb) => cb(value_address.item_id, true));
                        }
                    }
                    $scope.$digest();
                    PipeService.destroy('gh_value_get', value_address, pipeFnGet);
                }
                
                function pipeValueGet(event, field_value) {
                    if(field_value) {
                      $scope.value = field_value;
                    }
                    $scope.$digest();
                    PipeService.destroy('gh_value_get', value_address, pipeValueGet);
                }

                //It used to show checkmark near field name after value is updated
                function pipeValueUpdate(event, field_value) {
                    $scope.update_state = true;
                    $timeout(function() {
                        $scope.update_state = false;
                        $scope.$digest();
                    }, 1000);
                    $scope.value = field_value;
                    $scope.$digest();
                }
                
                $scope.$on("$destroy", () => {
                    PipeService.destroy('gh_model_get gh_model_update', model_address, modelPipe)
                    PipeService.destroy('gh_value_update', value_address, pipeValueUpdate);
                    PipeService.destroy('gh_value_update', value_address, pipeFn);
                    gudhub.destroy('settings_decorator', value_address, decorateElementSettingsCallback);
                })
                
                PipeService.on('gh_value_get', value_address, pipeValueGet, $scope).emit('gh_value_get', {}, value_address);
                PipeService.on('gh_value_update', value_address, pipeValueUpdate, $scope);

                //-- If GH-Element changes itemId we unsubscribe from previous item and subscribe for new item
                // e.g in gh-table we have virtual reepite that changes itemId
                $attrs.$observe('itemId', async function(newItemId, o) {
                    if (newItemId !== value_address.item_id) {
                        PipeService.destroy('gh_value_update', value_address, pipeValueUpdate);
                        PipeService.destroy('gh_value_update', value_address, pipeFn);
                        value_address.item_id = newItemId;
                        const value = await gudhub.getFieldValue(value_address.app_id, newItemId, value_address.field_id);
                        if(value) {
                            $scope.value = value;
                        } else {
                            if ($scope.value) { // fixes the same situation with field value that doesnt render and keep value of another item. The essence of this fix is to force the element to render
                                rendering(true);
                            }
                            $scope.value = null;
                        }
                        $scope.$digest();
                        PipeService.on('gh_value_update', value_address, pipeValueUpdate, $scope);
                    }
                });
            } else {
                angular.extend($scope.field_model, $scope.decorator);
                rendering();
            }

            //---------------------- RENDERING ELEMENT -------------------//
            async function rendering(isCompile = true) {

                const instanceData = await gudhub.ghconstructor.getInstance($scope.field_model.data_type);

                const interpr = await gudhub.getInterpretation($scope.field_model.field_value, $scope.field_model, $scope.field_model.data_type, $scope.elemSrc || 'form', $scope.itemId, $scope.appId, $scope.containerId);
                angular.extend($scope.field_model.settings, interpr.settings);
                
                if(!isCompile) {
                    GhElementFactory.populateWithStyle($element, interpr);
                    GhElementFactory.updateFieldNameStyles($element, interpr);
                    $scope.$apply();
                    return;
                }

                if($scope.field_model.settings.show_field) {
                    $element.empty();

                    let template = '';
                    let fieldName= '';

                    if($scope.field_model.settings.show_field_name) {
                        fieldName = GhElementFactory.renderFieldName($scope);
                    }

                    let ghElement = GhElementFactory.renderGhElement(interpr);
                    template = fieldName + ghElement;
                    
                    // Set attribute of element type
                    $element.attr("element-type", $scope.field_model.data_type.toString())

                    var el = $compile(angular.element(template))($scope);
                    $element[0].addEventListener('mouseenter', () => {
                        const history = $element[0].querySelector('.history_gh_element');
                        if (history) history.style.opacity = 1;
                    });
                    $element[0].addEventListener('mouseleave', (e) => {
                        if($scope.history.length) {
                            $scope.history = [];
                            $scope.$apply();
                        }
                        const history = $element[0].querySelector('.history_gh_element');
                        if (history) history.style.opacity = 0;
                    })
                    $element.append(el);

                    GhElementFactory.populateWithStyle($element, interpr);
                    GhElementFactory.updateFieldNameStyles($element, interpr);
                }

                if (instanceData && instanceData.getTemplate().constructor == "action") {
                    try {
                        instanceData.extendController($scope);
                    } catch (e) {
                        console.log(instanceData.getTemplate().name, " - Doesnt hase extendController() method");
                    }
                    $element.off("click");
                    $element.on("click", function (event) {
                        event.stopPropagation();
                        try {
                            instanceData.runAction($scope);
                        } catch (e) {
                            console.log(instanceData.getTemplate().name, " - Doesnt hase runAction() method");
                        }
                    });
                }
            }



            //------------------ AUTOMATION ------------------//
            

            function initAutomation(){
                let itemAddress = {
                    app_id: $scope.appId,
                    item_id: $scope.itemId
                };

                let automations = function(model, item){
                    return new Promise(async (resolve, reject) => {
                        var engine = new Rete.Engine('trigger@0.1.0');
                        let triggeredData = {
                            updated_item : [item],
                            new_value : [newValue],
                            old_value : [oldValue]
                        }
                        console.log("AUTOMATION START", triggeredData);
                        
                        //---- We reset value in order to know if current element's value was changed 
                        newValue = null;
                        oldValue = null;

                        const automationModulesList = gudhub.storage.getModulesList('automation');

                        const promises = [];

                        for(let module of automationModulesList) {
                            promises.push(new Promise(async (resolve) => {
                                let instance = await AutomationInstanceCreator.createInstance(module.data_type);
                                engine.register(instance);
                                resolve();
                            }));
                        }
                
                        await Promise.all(promises);

                        engine.on('error', ({ message, data }) => { console.log(message, data)});
                        engine.process(model, null, triggeredData, resolve, gudhub);
                    });
                }


                let valuePipeFn = async function valuePipeFn(event, item) {
                    let result = await automations($scope.field_model.data_model.automation.model, item);
                    
                    // Usually can return object, but it can't wrote as field value 
                    if(typeof result !== 'object') {
                        $scope.field_model.field_value = result;
                    }

                    if(result){
                        console.log("AUTOMATION FINISH", result);
                    }
                  };

                  
                //------ START -------//
                // Here we check if We need to start automation
                if ($scope.field_model.data_model.automation.active) {
                    gudhub.on('gh_item_update', itemAddress, valuePipeFn);
                    
                    $scope.$on('$destroy', () => {
                        gudhub.destroy('gh_item_update', itemAddress, valuePipeFn);
                    });
                    
                    console.log("automation", $scope.field_model.data_model.automation.active);
                }

            }




        }]
    };

    return directive;
}])


/*========================================================================================================|
|===============================================  GH-ELEMENT FACTORY   ===================================|
|=========================================================================================================|
*/
.factory('GhElementFactory', ['$q', '$compile', 'cnfg', 'GHConstructor', 'UtilsService', function($q, $compile, cnfg, GHConstructor, UtilsService) {

    

    return {

        populateWithStyle(element, interpretation) {
        let defaultInterpretations = 
            [{
                src: 'table',
                id: 'plain_text',
                settings:{
                    editable: 0,
                    show_field_name: 1,
                    show_field: 1
                }
            },{
                src: 'form',
                id: 'default',
                settings:{
                    editable: 1,
                    show_field_name: 1,
                    show_field: 1
                }
            },{
                src: 'dropdown',
                id: 'default',
                settings:{
                    editable: 0,
                    show_field_name: 0,
                    show_field: 1
                }
            },{
                src: 'input',
                id: 'default',
                settings:{
                    editable: 0,
                    show_field_name: 0,
                    show_field: 1
                }
            },{
                src: 'input_list',
                id: 'plain_text',
                settings:{
                    editable: 0,
                    show_field_name: 0,
                    show_field: 1
                }
            },{
                src: 'document',
                id: 'plain_text',
                settings:{
                    editable: 0,
                    show_field_name: 0,
                    show_field: 1
                }
            }];
            if (!interpretation.style) {

                var defaultStyles = {
                    style: {
                        font_size: '14',
                        font_style: '300'
                    }
                }
                
                let findedInterpretation = defaultInterpretations.find(intrpr => intrpr.src == interpretation.src);
                if(findedInterpretation) {
                    interpretation = gudhub.mergeObjects(findedInterpretation, defaultStyles);
                } else {
                    interpretation = gudhub.mergeObjects(interpretation, defaultStyles);
                }
            }
            
            const interpreterStyle = interpretation.style;
            element
                .attr("data-position", (interpreterStyle.position || "") + (parseInt(interpreterStyle.full_width) ? " full" : ""))
                .css('flex-direction', interpreterStyle.fieldName_position ? interpreterStyle.fieldName_position : '')
                .css('align-items', interpreterStyle.position == 'center' ? interpreterStyle.position: '');

            if(interpreterStyle.font_style != 'italic') {
                element.css("font-weight", interpreterStyle.font_style);
            } else {
                element.css("font-style", interpreterStyle.font_style);
            }
            
            element.css('font-size', interpretation.style.font_size ? interpretation.style.font_size + 'px' : '');
            element.css('color', interpretation.style.font_color ? interpretation.style.font_color : '');
        },

        updateFieldNameStyles(element, interpretation) {

            const interpreterStyle = interpretation.style || {};
            let fieldNameWrapper = element[0].querySelector('.field-wrap-name');
            if(interpreterStyle.fieldName_position == 'column') {
                fieldNameWrapper.classList.add('top_position');
              } else if (interpreterStyle.fieldName_position == 'column-reverse') {
                fieldNameWrapper.classList.add('top_position');
              } else if(interpreterStyle.fieldName_position == 'row') {
                fieldNameWrapper.classList.remove('top_position');
              }
              let fieldName = element[0].querySelector('.gh_element_name');
              
              if(fieldName) {
                fieldName.style.fontSize = interpreterStyle.fieldName_fontSize ? interpreterStyle.fieldName_fontSize + "px" : fieldName.style ? fieldName.style.fontSize : "";
                fieldName.style.color = interpreterStyle.color;
              }

        },

        renderFieldName(scope) {
            let template = `
            <span class="field-wrap-name" ng-show="field_model.settings.show_field_name"><span class="gh_element_name">
            <gh-history app-id="appId" item-id="itemId" field-id="fieldId" history="history" field-model="field_model" value="value"></gh-history>
            <span class="update_check" ng-if="update_state" ng-class="{'show_update': update_state}"></span>
            
                ${(scope.field_model.data_model ? (scope.field_model.data_model.tooltip ? '<gh-tool-tip model="field_model"></gh-tool-tip>' : '') : '')}
                ${scope.field_model.field_name}
                </span></span>`;

            return template;
        },

        renderGhElement(interpretation) {
            interpretation.html = interpretation.html || '';
            /* if cell value is empty then we create a dummy with <span/> tag */
            if (typeof interpretation.html === 'number' || interpretation.html.indexOf('<') !== 0) {
                interpretation.html = '<span>' + interpretation.html + '</span>';
            }
            
            return interpretation.html;
        }
    };
}])

//TODO make this possible from ghElement (breaks styles in project currently)
.directive('ghRelink', [
    '$compile',
    'PipeService',
    (
        $compile,
        PipeService,
    ) => ({
        transclude: true,
        // template: `<ng-transclude><span>Fallback content</span></ng-transclude>`,
        scope: true,
        controller: ['$scope', '$element', '$attrs', '$transclude', function($scope, $element, $attrs, $transclude) {
            let transclusionScope, transclusionContent;

            let fallbackLinkFn = $compile($element.contents());

            $scope.relink = async () => {
                $transclude((clone, scope) => {
                    try {
                        if (transclusionScope) transclusionScope.$destroy();
                        if (transclusionContent) transclusionContent.remove();

                        transclusionScope = scope;
                        transclusionContent = clone;

                        $element.append(clone)
                    } catch (e) {
                        fallbackLinkFn($scope, function(clone) {
                            $element.append(clone);
                        });
                    }
                });
            };

            $scope.relink();

            this.relink = $scope.relink;
        }],
        // link: async ($scope, $element, $attr, ctrl, $transclude) => {
        //
        // }
    })
])



/*======================================================================================================|
|===========================================   UTILS SERVICE   =========================================|
|=======================================================================================================|
|-- Service for development testing
*/
.service('UtilsService', [function() {

    //------------------------------ CROWLER ----------------------------------//
    this.crawling = function(object, action) {
        var properties = object === null ? [] : Object.keys(object); /* <--- Array of object properties */
        var self = this;


        /* <--- Array of object properties */
        /* We start crawling in case if object has properties */
        if (properties.length > 0) {
            angular.forEach(properties, function(prop) {
                var propertyValue = object[prop];

                /*  We won't go deeper into object if it's a string
                because string will be defined by 'Object.keys' as an arrey of letters
                */
                if (angular.isDefined(propertyValue) && typeof object != 'string') {

                    action(prop, propertyValue, object); /* <--- Action sends as argument to crawler */
                    self.crawling(propertyValue, action);
                }
            });
        }

        // object === null ?  properties =  object: properties = Object.keys(object);
        // properties = Object.keys(object);

    };

    /*----------------------------  GETTING COUNT OF WATHERS  -----------------------------*/
    // -- return count (number) of watchers with children watchers --
    this.getWatchersCount = function(element) {
        element = angular.element(element);
        var watcherCount = 0;

        function getElemWatchers(element) {
            var isolateWatchers = getWatchersFromScope(element.data().$isolateScope);
            var scopeWatchers = getWatchersFromScope(element.data().$scope);
            var watchers = (+scopeWatchers) + (+isolateWatchers);
            angular.forEach(element.children(), function(childElement) {
                watchers += getElemWatchers(angular.element(childElement));
            });
            return watchers;
        }

        function getWatchersFromScope(scope) {
            if (scope) {
                return scope.$$watchersCount;
            } else {
                return 0;
            }
        }

        return getElemWatchers(element);
    };

    this.mergeInterpretations = function(dst, src, byProperty) {
        var self = this;
        dst.forEach((interpr) => {
            src.forEach((srcInterpr) => {
                if (interpr[byProperty] == srcInterpr[byProperty]) {
                    for (var key in interpr) {
                        if (interpr.hasOwnProperty(key) && srcInterpr.hasOwnProperty(key)) {
                            if (key == 'settings') {
                                self.mergeInterpretationSettings(interpr.settings, srcInterpr.settings);
                            } else {
                                interpr[key] = srcInterpr[key];
                            }
                        }
                    }
                }
            });

        });

    };

    this.mergeInterpretationSettings = function(dst, src) {
        for (var key in src) {
            dst[key] = src[key];
        }
    };

    return this;
}]);