//gh-element-container reset css
require('./css/gh_element-container_reset_style.scss')


require('./config.js');
require('./storage/main_storage.js');
require('./core/authorization_contrl.js');
require('./apps_manager/applications_list/applications_contrl.js');
require('./apps_manager/appCtrl.js');
require('./user/user_settings.Ctrl.js');
require('./gui/gh-bind-html.js');
require('./gui/gh_auth_form.js');
require('./gui/gh_wally.js');

require('./gui/gh_icon/gh_icon.js');
// Web
require('./help/help.js');
require('./web/home/home_page_contrl.js');
require('./web/investors/investors.js');
require('./web/team/team.js');
require('./web/preview/previewCtrl.js');

require('./web/contact_us/contact_us.js');
// Dialog
require('./gui/dialog/gh_dialog.js');


require('./gui/loader/loader.js');

require('./css/style.scss');
require('./css/app.scss');

require('./css/form.scss');
require('./gui/gui.css');
require('./css/gui_settings.scss');
require('./css/gh-style.scss');
require('./gh_elements/document/plain/gh_text_editor.scss');


require('./apps_manager/applications_list/applications_list.html');
require('./apps_manager/app.html');
require('./web/home/home.html');

require('./gh_elements/edit_form/edit_template_service.js');
require('./gh_elements/edit_form/templates_generator_service.js');
require('./gh_elements/edit_form/gh_view.scss');
// require('./gh_elements/code_editor/code_editor.js');

//--------- GUI
require('./gui/gh_button.js');
require('./gui/gh_avatar/gh_avatar.js');
require('./gui/gh_input/gh_input.js');
require('./gui/gh_input_list/gh_input_list.js');
require('./gui/gh-doc-repeat.js');
require('./gui/gh_checkbox_list.js');
require('./gui/gh_duration.js');
require('./gui/gh_radio_button.js');
require('./gui/gh_toast.js');
require('./gui/gh_filter/gh_filter.js');
require('./gui/gh_filter/gh_filter_search.js');
require('./gui/gh_switch/gh_switch.js');
require('./gui/gh_boolean/gh-boolean.js');
require('./gui/gh_embed/gh_embed.js');
require('./gui/gh_user_selection.js');
require('./gui/infographic/gh_infographic_counter.js');
require('./gui/infographic/gh_infographic_value.js');
require('./gui/gh_option_table/gh_option_table.js');
require('./gui/gh_range.js');
require('./gui/gh_loader/gh_loader.js');
require('./gui/gh_internet_connection_lost/lostConnectionDirective.js');

//--------- UTILITES
require('./gh_elements/api/automation_mock.js');
require('./automation/automationContainer.js');
require('./automation/automationComponent.js');
require('./automation/gh_automation_dialog.js');
require('./core/gh_element/gh_element_automation_debugger.js');
require('./automation/iterator_debugger.js');

//--------- CORE
require('./core/app_processor.js');
require('./core/item_processor.js');
require('./core/document_manager.js');
require('./core/file_manager.js');
require('./core/filter/filter.js');
require('./core/send_email_service.js');
// require('./core/filter/groupe_service.js');
require('./core/gh_constructor.js');
require('./core/pipe.js');
require('./core/gh_element/gh_element.scss');
require('./core/gh_view/gh_view.js');
require('./core/sharing_api.js');
require('./core/gh_element/gh_element.js');
require('./core/gh_window/gh_window.js');
require('./core/gh_element/gh_element_editor.js');
// require('./core/items_filter.js');
require('./core/file_helper.js');
require('./core/gh_view/setViewTemplate.service.js');
require('./core/ghTooltip.js');
require('./core/gh_history/ghHistory.js');


require('./gh_elements/calculator/gh-calculator.js');
require('./gh_elements/close/gh_close.js');
/*Test*/
require('./gh_elements/timeline/gh_timeline.scss');
require('./gh_elements/color_data/gh_color.scss');


//sentry
import { GUDHUB_SETTINGS } from './config';
import * as Sentry from "@sentry/browser";
import { GudHub } from '@gudhub/core';

//sentry doesn't send anything without dsn
Sentry.init({
  dsn: GUDHUB_SETTINGS.sentry_dsn,
  onerror: true,
  onunhandledrejection: true,
//  allowUrls: ['gudhub.com']
});

let authKey = window.localStorage.auth_key ? window.localStorage.auth_key : false;

window.gudhub = new GudHub(authKey, {
  server_url : GUDHUB_SETTINGS.server_url,
  node_server_url: GUDHUB_SETTINGS.node_services_api_path,
  wss_url: GUDHUB_SETTINGS.ws_url + '/ws/app/',
  initWebsocket: GUDHUB_SETTINGS.enable_websockets,
  activateSW: false,
  swLink: 'service-worker.js',
  // Path to modules
  async_modules_path: GUDHUB_SETTINGS.async_modules_path,
  // File server url
  file_server_url: GUDHUB_SETTINGS.file_server_url,
  // automation modules path
  automation_modules_path: GUDHUB_SETTINGS.automation_modules_path
});
/*====================================================================================================*/
/*=========================================== GUDHUB LIBS   ==========================================*/
/*====================================================================================================*/
/*

  It used for two reasons:

  1) Connecting GUDHUB modules to main.js
  2) Using GUDHUB functionality for other websites especialy for wiggets

*/

angular.module('gudhubLibs', [
  'config',
  'ghElement',
  'ghWindowModule',
  /* External modules: */
  'ngAnimate',
  'ngRoute',
  'ngCookies',
  'ngAria',
  'ngMessages',
  'ngMaterial',
  /* GUI: */
  'ghAvatarModule',
  'ghInputModule',
  'loaderModule',
  'ghInputListModule',
  'ghDocumentRepeat',
  'ghCheckboxListModule',
  'ghToastModule',
  'ghFilterModule',
  'ghSwitchModule',
  'ghBooleanModule',
  'ghUserSelectingModule',
  'ghInfographicCounterModule',
  'ghInfographicValueModule',
  'ghButtonModule',
  'ghOptionTableModule',
  'cssLoaderModule',
  'ghDurationModule',
  'ghRadioButtonModule',
  'ghFilterSearchModule',	
	'ghRangeModule',
	'connectionModule',
  'wallyModule',
  /* Our modules: */
  'authorizationMod',
  'homePageMod',
  'applicationsCtrlMod',
  'previewCtrlModule',
  'appCtrlMod',
  'userSettingsMod',
  'groupsTableModule',
  'membersTableModule',
  'appsTableModule',
  'appsListModule',
  'groupUserModule',
  'integrationsModule',
  'automationElementsModule',
  'automationActivatorModule',
  'apiElementsTableModule',
  'triggerElementsTableModule',
  'taskElementsTableModule',
  // 'appMod',
  'ghBindHtml',
  'mainStorage',
  'ghAuthFormModule', /* Template auth for login/signup/update user */
  'oc.lazyLoad',
  'iconsGenerator',
  'pipeModule',
  'GhDialogModule',
  'GhTemplateModule',
  'editTemplateService',
  'templatesGeneratorService',
  'helpModul',
  'sharingApiModule',
  // UTILITES
  'ghAutomationMockModule',
  'automationContainerModule',
  'automationComponentModule',
  'ghAutomationDialogModule',
  'jsonFormatter',
  // CORE
  'appDataProcessor',
  'sendingEmailModule',
  'itemDataProcessor',
  'fileManagerModule',
  'fileHelperModule',
  'tableFilter',
  'ghEmbedModule',
  'ghConstructor',
  'ghElementEditorModule',
  'documentManagerModule',
  'ghCalculatorModule',
  'ghCloseModule',
  'setViewTemplateModule',
  'ghTooltipModule',
  'ghHistoryModule',
  // WEB
	'investorsModul',
	'teamModul',
  'contactusModule',
  'ghElementAutomationDebuggerModule',
  'iteratorDebuggerModule'
]);






/*====================================================================================================*/
/*=========================================== GUDHUB APP   ===========================================*/
/*====================================================================================================*/
var app = angular.module('gudhub', [
  'gudhubLibs'
]);



/*===========================================  MAIN CONTROLLER   ==========================================*/
app.controller('MainCtrl', [ 'cnfg', '$injector', function(cnfg, $injector) {
  // Set edit data to 'true' for edit views and field data
	cnfg.show_context_menu = true;

  // Temporary fix for ghconstructor in library.
  // Need to be deleted after new ghconstructor in library will be finished
  gudhub.ghconstructor.initAngularInjector($injector);
}])



/*==========================================  APP INITIALIZATION    =======================================*/
app.run(['cnfg', '$templateRequest', '$compile', '$location', '$window', '$ocLazyLoad', 'authApi', 'storage', '$route', '$rootScope', function(cnfg, $templateRequest, $compile, $location, $window, $ocLazyLoad, authApi, storage, $route, $rootScope) {
  cnfg.initLogMessages();
  $templateRequest('/web/wally_templates.html', true).then(function(html) {
    var compiled = $compile(html);
  });


  // Send tracker google analitic for dunamic url
  $rootScope.$on('$viewContentLoaded', function(event) {
    if (!$window.ga) {
      return;
    }
    $window.ga('tracker.send', 'pageview', {page: $location.url()});
  });
  if(!$window.localStorage.auth_key && !/\/preview.?/g.test($location.$$path)){
    $location.path('/login');
    $route.reload();
  }
  /* -- Check authentification of user and stop route on firt load template*/
  function routeChange(event, current, next) {
    /* Stop change route */
    event.preventDefault();

    if ($window.localStorage.auth_key && !/\/preview.?/g.test($location.$$path)) {

      authApi.updateToken($window.localStorage.auth_key).then(function(res ) {
        storage.updateUser(res);
        gudhub.getAppsList();
        //$location.path($location.path() === '/' ? '/apps' : $location.path());
        return $location.path() === '/' ? '/apps' : $location.path();
      }, function(err) {

        return '/login';
      }).then(function(routePath) {
        // Destroy listener on $routeChangeStart
        routeChangeOff();
        // Reload rout

        if (routePath == $location.path()) {
          $location.path(routePath);
          // Reload rout
          $route.reload();
        } else {
          $location.path(routePath);
        }
        $rootScope.$apply();

      });

    } else {
      routeChangeOff();
      $route.reload();
    }
  }

  /* Listener on app init(load) */
  var routeChangeOff = $rootScope.$on('$routeChangeStart', routeChange);



/**************** WEB COMPONENT TESTING CODE *****************/
//First version of GhElement. For testing purpose only
  class GhElement extends HTMLElement {
    constructor() {
      super();
      // Creating Shadow DOM
      //this.attachShadow({ mode: "open" });
    }
  
    async connectedCallback() {
      let self = this;
      let appId =  this.getAttribute("app-id");
      let itemId = this.getAttribute("item-id");
      let fieldId = this.getAttribute("field-id");
      let containerId = this.getAttribute("container-id");
      let elemSrc = this.getAttribute("elem-src");
      let decoratorString = this.getAttribute("decorator");
      let valueString = this.getAttribute("value");
      console.log("appId",appId);

      let value = await gudhub.getFieldValue(appId, itemId, fieldId);
      let model = await gudhub.getField(appId, fieldId);
      let decorator = null;
      let mergedModel = null;
      eval('decorator='+decoratorString);//temporary code to convert decorator into object

      //**** Merging Field models ****/
      if(model && decorator) mergedModel = gudhub.mergeObjects(model,  decorator);

      //**** Merging Field models ****/
      if(model && decorator) mergedModel = gudhub.mergeObjects(model,  decorator);
      if(!model && decorator) mergedModel = decorator;
      if(model && !decorator) mergedModel = model;

      //*** Hendeling Fields Updates ***/
      Object.defineProperty(mergedModel, 'field_value', {
        get: function() {
          console.log("GET VALUE");
            return value;
        },
        set: function(newValue) {
            if (newValue != value){
                console.log("SET VALUE", value, newValue);
                gudhub.fieldProcessor.setFieldValue(appId, itemId, fieldId, newValue);
            }
        }
      });

	  	//**** For AngularJS Directves ****/
      angular.element(document).ready(function() {
        let $scope = $rootScope.$new();
        let elem = angular.element(self);
        const $injector = elem.injector();
        
        $injector.get('interpritate').getInterpretation(value, mergedModel, mergedModel.data_type, elemSrc, containerId).then(function(interpretation){
            //Generating HTML
            let fieldNameHtml = interpretation.settings.show_field_name ? `<span class="field-wrap-name"><span class="gh_element_name">${mergedModel.field_name}</span></span>` : ``;
            let interptHtml = angular.element(fieldNameHtml + interpretation.html);

            //Generating CSS Styles
            self.style.fontSize = (interpretation.style ? (interpretation.style.font_size ? interpretation.style.font_size+'px' : '') : '');
            self.setAttribute('data-position', (interpretation.style ? (interpretation.style.position ? interpretation.style.position :'') : '')); 

            //Generating $Scope
            $scope.field_model = mergedModel;
            $scope.field_model.settings = interpretation.settings;
            $scope.field_model.field_value = value;
            $scope.appId = appId;
            $scope.itemId = itemId;
            $scope.fieldId = fieldId;
            $scope.containerId = containerId;
            $scope.value = value;
            $scope.elemSrc = elemSrc;
            
            //Compile & Appending
            $compile(interptHtml)($scope);
            elem.append(interptHtml);
          });
      });

        //this.shadowRoot.innerHTML = `<strong>${model.field_name}</strong>:${value}`;
    }
  }
  
  
  // Registering "gh-element" as HTML teg
  window.customElements.define("gh-elementt", GhElement);

  //**** THE END of WEB COMPONENT 

}]);


/*========================================  ROUTING CONFIGURATION    ======================================*/
app.config([ '$routeProvider', '$locationProvider', '$httpProvider', '$compileProvider', '$mdGestureProvider',function($routeProvider, $locationProvider, $httpProvider, $compileProvider, $mdGestureProvider) {

  // add to white list blob urls
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(blob|http|https|mailto|tel|slack|viber):/);

  $routeProvider
    .when('/', {
      templateUrl: 'web/home/home.html',
      theme: 'white'
    })
    .when('/home/:industry', {
      templateUrl: 'web/home/home.html',
      theme: 'white'
    })
    .when('/index.html', {
      redirectTo: '/'
    })
    .when('/app/:appId?/:itemId?/:edit?/:field?', {
      templateUrl: 'apps_manager/app.html'
    })
    .when('/act/:actionType?/:param_1?/:param_2?/:param_3?', {
      templateUrl: 'apps_manager/app.html',
      reloadOnSearch: false
    })
    .when('/apps', {
      templateUrl: 'apps_manager/applications_list/applications_list.html'
    })
    .when('/login', {
      templateUrl: '/user/login.html'
    })
    .when('/preview', {
      templateUrl: '/web/preview/preview.html'
    })
    .when('/signup/:email?', {
      templateUrl: '/user/signup.html'
    })
    .when('/usersettings', {
      templateUrl: '/user/user_settings.html'
    })
    .when('/help', {
      templateUrl: '/help/help.html'
    })
    .when('/no-such-page', {
      templateUrl: '/web/no_such_page.html'
    })
    .when('/gh-style', {
      templateUrl: '/web/gh-style.html'
    })
    .when('/help/:id?', {
      templateUrl: '/help/help.html'
    })
    .when('/investors/pitch-deck', {
      templateUrl: '/web/investors/pitch-deck.html'
		})
		.when('/team', {
			templateUrl: '/web/team/team.html'
		})
		.when('/contact_us', {
      templateUrl: '/web/contact_us/contact_us.html'
    })
    .otherwise({
      redirectTo: '/no-such-page'
    });

  $httpProvider.interceptors.push(function() {
    return {
      request: function(config) {
        /* add token or auth_key*/
        return config;
      }
    };
  });

  $locationProvider.html5Mode(true);
  /* $locationProvider.hashPrefix('!');*/

  // For mobile devices without jQuery loaded, do not
  // intercept click events during the capture phase.
  $mdGestureProvider.skipClickHijack();
}]);
