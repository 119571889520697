/**********************************************************************************************/
/************************************   GH TOAST    *******************************************/
/**********************************************************************************************/
// This module need for show info/error toasts messages for user
/**********************************************************************************************/

angular.module('ghToastModule', [])

.service('ghToastService', ['$mdToast', function($mdToast){

/**
 * Sort items by field value of given field
 * 
 * @param {string} text - text for notification
 * @param {string} position - position of notification - 'top right', 'top left', 'bottom right', 'bottom left'
 */
  this.simple = function(text, position){

    // If not text - not showing toast
    if(!text) return;

    var toast = $mdToast
      .simple()
      .position(position || 'bottom left')
      .textContent(text);

    return show(toast);
  };

  this.customToast = function (options){
    if(!options) return;
    if(options.scope) {
      options.scope.closeToast = $mdToast.hide;
    }

    const initOptions = {
      hideDelay: 4000,
      position: 'bottom right',
      ...options
    }
    
    return show(initOptions)
  }
  
  // Function to showing toast. Returning promise after close.
  // A promise that can be resolved with $mdToast.hide() or rejected with $mdToast.cancel().
  function show(toast){
    return $mdToast.show(toast);
  }

  return this;
}]);
