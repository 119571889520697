require('./gh_history.scss');
angular.module('ghHistoryModule', [])
.directive('ghHistory', [() => {
  return {
    restrict: 'E',
    scope: {
      appId: '=',
      itemId: '=',
      fieldId: '=',
      history: '=',
      fieldModel: '=',
      value: '='
    },
    template:
     `<div ng-if="value && appId && itemId" class="history_gh_element">
          <div ng-if="isHistoryExist" class="history_icon" ng-click="showHistory(field_model)"><span ng-class="{'active': history.length > 0}">H</span></div>
              <div ng-if="history.length > 0" class="history_wrapper">
                <div class="title">History</div>
                <div class="item" ng-repeat="item in history">
                    <gh-user-selecting class="history_user" gh-model="item.user_id" gh-field="userFieldModel" gh-dropdown="" date="item.timestamp" gh-mode="little_photo" delete-selected editable="false"></gh-user-selecting>
                    <div class="date">{{item.date}}</div>
                    <div class="updated_value">Updated to <span class="new_value" gh-element app-id="{{appId}}" field-id="{{fieldId}}" value="item.fieldModel.field_value" elem-src="table"></span></div>
                </div>
            </div>
       </div>`,
    link: async function(scope, element) {

      const item = await gudhub.getItem(scope.appId, scope.itemId);
      const currentField = item?.fields.find(field => field.field_id == scope.fieldId);
      scope.isHistoryExist = currentField?.history?.length
      scope.$apply()

      scope.userFieldModel = {
        data_model: {
          photo_size: 40,
          display_type: 'photo_fullname'
        },
        data_type: 'user'
    };

    scope.formatDate = (ms) => {
      const date = new Date(ms);
    
      const options = {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
      };
      
      return date.toLocaleString('en-GB', options).replace(',', '');
    }

    scope.showHistory = async () => {
        if(!scope.history.length) {
            for(let i = 0; i < currentField?.history.length; i++) {
                const historyItem = currentField.history[i];
                const modelClone = angular.copy(scope.fieldModel);
                modelClone.field_value = historyItem.field_value;
                historyItem.date = scope.formatDate(historyItem.timestamp)
                historyItem.fieldModel = modelClone;
                scope.history.push(historyItem);
            }
        }
    }
    }
  };
}]);