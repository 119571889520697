import { AutomationInstanceCreator } from "../../automation/AutomationInstanceCreator";
angular.module('ghElementSettingsModule', [])

/*============================================================================================================*/
/*====================================    SETTING FIELD DIRECTIVE    ========================================*/
/*===========================================================================================================*/

/*---------------------- APPLICATION FOR HELP ----------------------------------*/
/* We use this application to get data for tooltip */
// app_help: {
//   app_id: 7054,
//     field_id_map: 82396,
//     field_id_description: 82391
// }
/*------------------------------------------------------------------------------*/


.directive('fieldSetting', ['$compile', 'fieldSettingsService', function($compile, fieldSettingsService) {
  let directive = {
    restrict: 'E',
    customazible: '='
  };
  directive.link = function (scope, element) {
    fieldSettingsService.getSettingHtml(scope, scope.customazible).then(function (result) {
      element.empty().html( result );
       $compile( element.contents() )(scope);

       // Add class
       if(scope.setting.class){
        element.parent().addClass(scope.setting.class);
       }

      //  console.log('MAIN SCOPE: ', scope);

      //  if(scope && scope.setting && scope.settings.data_model) {
      //     // scope.settin
      //  }

      //  scope.trigger = {
      //    omnomnom: true
      //  }
    });
  };

  return directive;
}])
/*============================================================================================================*/
    /*===================================     FIELD PREVIEW DIRECTIVE    ========================================*/
    /*===========================================================================================================*/
    /* This directive generates fields sample in Field Editor*/

    .directive('fieldPreview', ['$compile', function($compile) {
      var directive = {};

      directive.scope = {
        fieldModel: '=',
        elemSrc: '@',
        value: '=',
        showField: '=', // Needs to show hide field in editing form
        interpretationType: '@', // type of interpretation
        interpretationObjIndex: '@', // index of interpretation in data_model interpretation array. Must be a number
        interpretation: '='
      };

      directive.link = function(scope, elem, attrs) {
      // Private compile scope
        var privateFieldPreviewScope = scope.$new();

        scope.fieldModelScope = {};
        //-- These staff is run each time when we change directive's attribute
        scope.$watch('fieldModel', function(newValue, oldValue) {
          if(newValue != oldValue){
            privateFieldPreviewScope.$destroy();
            renderFieldPreview();
          }
        }, true);

        //-------- RENDERING FIELDS SETTINGS -------------
        function renderFieldPreview() {

          // If not present source, delete field interpretation
          if(!scope.elemSrc){
            elem.empty();
            return;
          }

          // This need to set current interpretation, when we have more than one
          // same interpretation place

          scope.field_model = angular.merge(angular.copy(scope.fieldModel), {
            data_model:{
              interpretation: []
            },
            field_value: scope.value
          });



          if(!scope.field_model.data_model.interpretation.length){
            scope.field_model.data_model.interpretation = [scope.interpretation];
          }



          // This need to set current interpretation, when we have more than one
          // same interpretation place and diferend interpretation for one place

          var index = scope.interpretationObjIndex;

          let editorInterpritation = scope.field_model.data_model.interpretation.find(i => i.src == 'editor');
          let editorInterpritationSrc;

          if (editorInterpritation) {
            editorInterpritationSrc = editorInterpritation.src;
          }

          scope.field_model.data_model.interpretation = scope.field_model.data_model.interpretation.filter(function (intrpr, i) {
            return index ? i == index : true;
          }).map(function (intrpr) {
            angular.merge(intrpr, {
              id: editorInterpritationSrc ? editorInterpritationSrc : scope.interpretationType || intrpr.id,
              settings: {
                show_field: scope.showField || intrpr.settings.show_field
              }
            });

            return intrpr;
          });

          //-- Constructing Field
          var fieldPreviewHtml = '<div class="field" gh-element decorator="field_model" elem-src="{{elemSrc}}" ></div>';

          privateFieldPreviewScope = scope.$new();
          //-- Rendering Field
          var el = angular.element(fieldPreviewHtml);
          var compiled = $compile(el);
          elem.empty();
          elem.append(el);
          compiled(privateFieldPreviewScope);
        }

        renderFieldPreview();
      };

      return directive;
    }])

.service('fieldSettingsService', ['$injector', 'PipeService',  'fileManager', 'cnfg', '$q', function ($injector, PipeService, fileManager, cnfg, $q) {


  // Returm html template for each field setting type
  this.getSettingHtml = async function (scope, settingCustomization = false) {
    let deferred = $q.defer();
    let negativeExpr = '';
    let templateList = {
      header: function (setting) {
        if (setting.showIf && setting.showIf.indexOf('!') == 0) {
          negativeExpr = '!';
          setting.showIf = setting.showIf.slice(1);
        }
        return '<h3 ' + (setting.showIf ? 'ng-show="' + negativeExpr + 'fieldModel.' + setting.showIf + '"' : '') + '>' + setting.title + '</h3>';
      },
      ghElement: function (setting) {
        if (setting.showIf && setting.showIf.indexOf('!') == 0) {
          negativeExpr = '!';
          setting.showIf = setting.showIf.slice(1);
        }

        if(settingCustomization && setting.hasOwnProperty('customazible') && !setting.customazible) {
          return '';
        }

        return ' <div class="flex">' +
          '   <div ' + (setting.showIf ? 'ng-show="' + negativeExpr + 'fieldModel.' + setting.showIf + '"' : '') + ' gh-element decorator="field_model" value="fieldModel.' + setting.property + '" elem-src="form"></div>' +
          ' </div>';
      },
      html: function (setting) {
        if (setting.showIf && setting.showIf.indexOf('!') == 0) {
          negativeExpr = '!';
          setting.showIf = setting.showIf.slice(1);
        }
        return '<div class="flex"' + (setting.showIf ? 'ng-show="' + negativeExpr + 'fieldModel.' + setting.showIf + '"' : '') + '>' + setting.control + '</div>';
      }
    };

    // Set field model to scope
    scope.field_model = {};
    let instance, template;

    if (typeof scope.setting.data_model == 'function') {
      angular.extend(scope.field_model, await scope.setting.data_model(scope.fieldModel, scope.appId));
    }

    // Initialization init function if it present
    if (typeof scope.setting.onInit == 'function') {
      scope.setting.onInit(scope, scope.fieldModel, scope.setting);
    }
    // Generation text for ToolTip
    if(scope.field_model && scope.fieldModel.data_type){
      if (!scope.field_model.data_model) {
        scope.field_model.data_model = {};
      }
      // Need to check element type and use different methods
      if(scope.fieldModel.constructor == 'node') {
        instance = await AutomationInstanceCreator.createInstance(scope.fieldModel.data_type);
        template = instance.getTemplate();
      } else {
        instance = await gudhub.ghconstructor.getInstance(scope.fieldModel.data_type);
        template = instance.getTemplate();
      }

      if (!scope.field_model.data_model.tooltip) {
        scope.field_model.data_model.tooltip = {};
      }
      scope.field_model.data_model.tooltip = {
        ...scope.field_model.data_model.tooltip,
        filter_valuesArray : [scope.fieldModel.data_type, template.constructor]
      }
    }

      deferred.resolve( templateList[scope.setting.type](scope.setting) );

    return deferred.promise;
  };

  //Return default setting type settings
  this.getDefaultSettings = function (settingsType) {
      
    let defaultSettings = {

      // ----------------------------------------- GENERAL SETTING --------------------------------------------
      // ------------------------------------------------------------------------------------------------------
      general_setting: [
        {
          title: 'Options',
          type: 'general_setting',
          icon: 'menu',
          columns_list:[
            [
              {
                title: 'Field Settings',
                type: 'header'
              },{
              type: 'ghElement',
              property: 'field_name',
              customazible: false,
              data_model: function () {
                return {
                  field_name: 'Field Name',
                  name_space: 'field_name',
                  data_type: 'text',
                  data_model: {
                    tooltip: {
                      default: '<h3>Field Name</h3><p>Here you can change the name of the field.</p>',
                      en: 'EN',
                      ua: 'UA'
                    }
                  }
                };
              }
            },{
              type: 'ghElement',
              property: 'name_space',
              customazible: false,
              data_model: function () {
                return {
                  field_name: 'Name space',
                  name_space: 'name_space',
                  data_type: 'text',
                  data_model: {
                    tooltip: {
                      default: `<h3>Name space</h3><p>Name space is text identification field.
                        It is used for data export/import and binding with other applications.
                        The value for this field is set by default, but can be changed if necessary.
                        Name space field must not contain capital letters or blank spaces.</p>`,
                      en: 'EN',
                      ua: 'UA'
                    }
                  }
                };
              }
            }
            ]
          ]
        },{
          title: 'Style',
          type: 'general_setting',
          icon: 'eye',
          columns_list:[
            [
              {
                type: 'html',
                class: 'interpretation_type_settings_table',
                data_model: function () {
                  return {};
                },
                control:
                  `<table ng-cloak>
                       <tr>
                         <th>Place</th>
                         <th>Condition Filter</th>
                         <th colspan="2">Edit</th>
                       </tr>
                       <tr ng-repeat="intr in fieldModel.data_model.interpretation">
                         <td>{{(intr.src.charAt(0).toUpperCase() + intr.src.slice(1)).replace(\'_\', \' \')}}</td>
                         <td><div gh-icon="checked 86BEF6 35px normal" ng-if="intr.settings.condition_filter"></div></td>
                         <td gh-icon="pencil 86BEF6 25px normal" ng-click="editStyle($index)"></td>
                         <td gh-icon="rubbish 86BEF6 25px normal" ng-click="fieldModel.data_model.interpretation.splice($index, 1)"></td>
                       </tr>
                       <tr class="transition">
                         <td colspan="4" ng-click="addInterpreter()">
                           <div><span gh-icon="plus fff 25px normal"></span><span>Add interpretation</span></div>
                         </td>
                       </tr>
                   </table>`
              }
            ]
          ]
        },{
          title: 'Embed',
          type: 'general_setting',
          icon: 'embed',
          columns_list:[
            [
              {
                type: 'html',
                class: 'option-column_800_size',
                data_model: function () {
                  return {};
                },
                control: '<gh-embed app-id="{{appId}}" field-id="{{fieldModel.field_id}}" field-data-type="{{fieldModel.data_type}}"></gh-embed>'
              }
            ]
          ]
        }, {
          title: 'Code',
          type: 'general_setting',
          icon: 'code',
          columns_list:[
            [
              {
                type: 'ghElement',
                class: 'option-column_full_size',
                property: 'data_model.code',
                data_model: function () {
                  return {
                    data_model:{
                      code_mode: 'javascript',
                      save_file: false,
                      interpretation:[
                        {
                          src: 'form',
                          settings:{
                            show_field_name: 0
                          }
                        }
                      ],
                    },
                    field_name: 'Code Editor',
                    name_space: 'code_editor',
                    data_type: 'code_editor'
                  };
                },
                // control: '<gh-code-editor model="fieldModel.data_model" destination-property="code" mode="javascript" editable="1"></gh-code-editor>'
              },
            ]
          ]
        },{
          title: 'Tooltip',
          type: 'general_setting',
          icon: 'txt',
          columns_list:[
            [
              {
                type: 'ghElement',
                class: 'option-column_full_size',
                property: 'data_model.tooltip.default',
                data_model: function () {
                  return {
                    data_model:{
                      code_mode: 'htmlmixed',
                      save_file: false,
                      interpretation:[
                        {
                          src: 'form',
                          settings:{
                            show_field_name: 0
                          }
                        }
                      ],
                    },
                    field_name: 'Code Editor',
                    name_space: 'code_editor',
                    data_type: 'code_editor'
                  };
                },
              },
              //   control: '<gh-code-editor model="fieldModel.data_model" destination-property="tooltip.default" mode="htmlmixed" editable="1"></gh-code-editor>'
            ]
          ]
        },{
          title: 'Automation',
          type: 'general_setting',
          icon: 'menu',
          columns_list: [
						[
              {
                type: 'ghElement',
                class: 'automation-activation-in-settings',
                property: 'data_model.automation.active',
                data_model: function () {
                  return {
                    field_name: 'Activate',
                    name_space: 'activate',
                    data_type: 'boolean'
                  };
                }
              },
              {
                type: 'html',
                class: 'option-column_full_size',
                data_model: async function (fieldModel, a, b) {

                  if(typeof fieldModel.data_model.trigger === 'undefined') {
                    fieldModel.data_model.trigger = {
                      debug: false
                    }
                  }

                  fieldModel.debugInfo = {
                    debuggers: {
    
                    },
                    currentNodes: []
                  }

                  let module = await gudhub.ghconstructor.getInstance(fieldModel.data_type);
                  let constructorType = module.getTemplate().constructor;

                  //adding default automation model to element if it doesn't have it
                  if ( !fieldModel.data_model.hasOwnProperty('automation') && constructorType == 'field'){
                    fieldModel.data_model.automation = {
                      active: false,
                      model:{"id":"trigger@0.1.0","nodes":{"1":{"id":1,"data":{},"inputs":{},"outputs":{"updatedItems":{"connections":[]}},"position":[80,200],"name":"Gh Element Node"},"8":{"id":8,"data":{"output_value":null, data_type: "PopulateElement"},"inputs":{"inpItems":{"connections":[]}},"outputs":{},"position":[605.07421875,193.32421875],"name":"Populate Element"}}}
                    }
                  }

                  return {
										include_nodes: gudhub.storage.getModulesList('automation', false, 'GhElement')
									};
                },
								control: /*html*/`
                  <div class="gh-element-automation">
                  <gh-automation app-id="appId" debug-info="fieldModel.debugInfo" element-id="elementId" trigger="fieldModel.data_model.trigger" gh-model="fieldModel.data_model.automation.model" nodes-model="field_model"></gh-automation>
                  <gh-element-automation-debugger debug-info="fieldModel.debugInfo" app-id="appId" element-id="elementId" model="fieldModel"></gh-element-automation-debugger>
                  </div>
                `
              },
						]
					]
				},
        {
          title: 'Help',
          type: 'general_setting',
          icon: 'question',
          columns_list:[
            [
              {
                type: 'html',
                class: 'option-column_full_size',
                data_model: function () {
                  return {};
                },
                control: '<gh-tool-tip model="field_model" mode="help"></gh-tool-tip>'
              }
            ]
          ]
        }
      ],

      // ----------------------------------- INTERPRETATION SETTING --------------------------------------
      // -------------------------------------------------------------------------------------------------
      interpretation_setting:[
        {
          title: 'Style',
          icon: 'eye',
          type: 'interpretation_setting',
          columns_list:[
            [{
              title: 'General settings',
              type: 'header'
            },{
              type: 'ghElement',
              property: 'interpreter.src',
              data_model: async function (fieldModel) {
                let module = await gudhub.ghconstructor.getInstance(fieldModel.data_type);
                let options = module.getTemplate().model.data_model.interpretation.map(function (intrpr) {
                  return {
                    name: (intrpr.src.charAt(0).toUpperCase() + intrpr.src.slice(1)).replace('_', ' '),
                    value: intrpr.src
                  };
                });

                options.push({
                  name: 'Container',
                  value: fieldModel.container_id
                });

                return {
                  data_model:{
                    // List of element places interpretations
                    options: options
                  },
                  field_name: 'Source place',
                  data_type: 'text_opt'
                };
              }
            },{
              type: 'ghElement',
              property: 'interpreter.settings.editable',
              data_model: function () {
                return {
                  field_name: 'Editable',
                  data_type: 'boolean'
                };
              }
            },{
              type: 'ghElement',
              property: 'interpreter.settings.show_field',
              data_model: function () {
                return {
                  field_name: 'Show field',
                  data_type: 'boolean'
                };
              }
            },
            {
              type: 'ghElement',
              property: 'interpreter.settings.condition_filter',
              data_model: function () {
                return {
                  field_name: 'Use Condition Filter',
                  name_space: 'condition_filter',
                  data_type: 'boolean'
                };
              }
            },
            {
              showIf: 'interpreter.settings.condition_filter',
              title: 'Condition Filter',
              type: 'header'
            },
            {
              showIf: 'interpreter.settings.condition_filter',
              type: 'html',
              onInit: function (settingScope,) {
                settingScope.$watch(function () {
                    return settingScope.fieldModel.app_id;
                }, function (newValue) {
                    settingScope.field_model.recipient.app_id = newValue;
                });
              },
              data_model: function (fieldModel) {
                return {
                  recipient:{
                    app_id: fieldModel.app_id
                  }
                };
              },
              control:
                '<gh-filter gh-filter-data-model="field_model" filter-list="fieldModel.interpreter.settings.filters_list" gh-mode="variable"></gh-filter>'
            }], [{
              title: 'Field Style',
              type: 'header'
            },{
              type: 'ghElement',
              property: 'interpreter.style.font_size',
              data_model: function () {
                return {
                  field_name: 'Font Size',
                  name_space: 'font_size',
                  data_type: 'number'
                };
              }
            },{
              type: 'ghElement',
              property: 'interpreter.style.font_color',
              data_model: function () {
                return {
                  data_model:{
                    interpretation:[
                      {
                        src: 'form',
                        settings:{
                          show_field_name: 1
                        }
                      }
                    ],

                  },
                  field_name: 'Font Color',
                  data_type: 'color'
                };
              }
            },{
              type: 'ghElement',
              property: 'interpreter.style.font_style',
              data_model: function () {
                return {
                  data_model:{
                    multiple_value: 0,
                    interpretation:[
                      {
                        src: 'form',
                        settings:{
                          show_field_name: 0
                        }
                      }
                    ],
                    options:[                   
                      { name: 'Regular', value: '300', color: '#0D99FF', icon: 'font_style_regular'},
                      { name: 'Bold', value: '700', color: '#0D99FF', icon: 'font_style_bold'},
                      { name: 'Italic', value: 'italic', color: '#0D99FF', icon: 'font_style_italic'},
                    ],
                    show_field_name: false
                  },
                  data_type: 'radio_icon'
                }
              }
            },{
              title: 'Alignment',
              type: 'header'
            },{
              type: 'ghElement',
              property: 'interpreter.style.position',
              data_model: function () {
                return {
                  data_model:{
                    multiple_value: 0,
                    interpretation:[
                      {
                        src: 'form',
                        settings:{
                          show_field_name: 0
                        }
                      }
                    ],
                    options:[
                      { name: 'Left', value: 'left', color: '#d1d8dd', icon: 'left_position' },
                      { name: 'Right', value: 'right', color: '#d1d8dd', icon: 'right_position' },
                      { name: 'Center', value: 'center', color: '#d1d8dd', icon: 'center_position' },
                      { name: 'Justify', value: 'beetwen', color: '#d1d8dd', icon: 'beetwen_position' }
                    ],
                    show_field_name: false
                  },
                  data_type: 'radio_icon'
                };
              }
            },{
              title: 'Field Name Style',
              type: 'header'
            },{
              type: 'ghElement',
              property: 'interpreter.settings.show_field_name',
              data_model: function () {
                return {
                  field_name: 'Show field name',
                  data_type: 'boolean'
                };
              }
            },{
              type: 'ghElement',
              showIf: 'interpreter.settings.show_field_name',
              property: 'interpreter.style.fieldName_fontSize',
              data_model: function () {
                return {
                  field_name: 'Font Size',
                  name_space: 'font_size',
                  data_type: 'number'
                };
              }
            },{
              type: 'ghElement',
              showIf: 'interpreter.settings.show_field_name',
              property: 'interpreter.style.color',
              data_model: function () {
                return {
                  data_model:{
                    interpretation:[
                      {
                        src: 'form',
                        settings:{
                          show_field_name: 1
                        }
                      }
                    ],

                  },
                  field_name: 'Font Color',
                  data_type: 'color'
                };
              }
            },{
              type: 'ghElement',
              showIf: 'interpreter.settings.show_field_name',
              property: 'interpreter.style.fieldName_position',
              data_model: function () {
                return {
                  data_model:{
                    multiple_value: 0,
                    interpretation:[
                      {
                        src: 'form',
                        settings:{
                          show_field_name: 0
                        }
                      }
                    ],
                    options:[
                      { name: 'Left', value: 'row', color: '#d1d8dd', icon: 'fieldName_left' },
                      { name: 'Top', value: 'column', color: '#d1d8dd', icon: 'fieldName_top' },
                      { name: 'Bottom', value: 'column-reverse', color: '#d1d8dd', icon: 'fieldName_bottom' }
                    ],
                  },
                  data_type: 'radio_icon'
                };
              }
            }], [{
              title: 'Interpretation type',
              type: 'header'
            },{
              type: 'html',
              class: 'interpretation-type',
              onInit: async function (scope, fieldModel) {
                let module = await gudhub.ghconstructor.getInstance(fieldModel.data_type);

                // List of element interpretations
                scope.setting.interpretations = module.getInterpretationsList();
                console.log(scope.setting.interpretations);
              },
              control:
                '<md-radio-group ng-model="fieldModel.interpreter.id" class="interpretation-type">' +
                '   <md-radio-button ng-repeat="interpretation in setting.interpretations" ng-value="interpretation.id" aria-label="{{interpretation.id}}">' +
                '      <span style="font-size: 11px; color: #bbbcc5;">{{interpretation.name}}</span>'+
                '       <field-preview interpretation="interpretation" field-model="fieldModel" elem-src="{{fieldModel.interpreter.src || \'form\'}}" value="value" show-field="1" interpretation-type="{{interpretation.id}}" interpretation-obj-index="{{interpretationObjIndex}}"></field-preview>' +
                '   </md-radio-button>' +
                '</md-radio-group>'
            }]
          ]
        }
      ]
    };

    return defaultSettings[settingsType] || [];
  };

  return this;
}])


